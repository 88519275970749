import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Button, Modal, Typography, Image, notification, FormInstance, Alert } from 'antd';
import posthog from 'posthog-js';
import { openModal } from '@/store/modal/slice';
import { makeTestCall, updateTestingNumber } from '@/api/reservedNumbers';
import { updateStepsCompleted, updatePendingTestingStatus, incrementTotalTestCalls } from '@/api/user';
import { setCurrentPage, setInstructionSteps, setTestingNumber } from '@/store/account/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import PlansModal from '@/components/PlansModal/PlansModal';
import { OnboardingSteps } from 'src/enums/IReceptionistTestingSteps';
import CustomOnboardingButton from '@/components/Onboarding/CustomButton';
import step_0_image from '@/assets/images/onboarding/step_0.gif';
import step_1_image from '@/assets/images/onboarding/step_1.gif';
import step_4_image from '@/assets/images/onboarding/step_4.gif';
import step_5_image from '@/assets/images/onboarding/step_5.gif';
import step_6_image from '@/assets/images/onboarding/step_6.gif';
import step_7_image from '@/assets/images/onboarding/step_7.gif';
import step_optional_onb_image from '@/assets/images/onboarding/step_optional_onb_call.gif';
import { PhoneOutlined } from '@ant-design/icons';
import { getLoggedInUser } from '@/store/account/selector';
import { MODAL_IDS } from 'src/enums/EModal';
import { CustomModal } from '@/components/CustomModal';
import { ChangeTestingNumberForm } from '../Steps/ChangeTestingNumberForm';
import { RECEPTIONIST_NUMBER } from 'src/constants';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import { useLocation, useNavigate } from 'react-router-dom';
import PricingPlansModal from './ViewPricingPlan';

const { Title, Paragraph } = Typography;
const TOTAL_CALLS_LIMIT = 5;

interface OnboardingProps {
  tourOpened?: boolean;
  setOpened: (open: boolean) => void;
  setOpenedOnboarding: (open: boolean) => void;
  setReceiveTestCallTourOpen: (open: boolean) => void;
  openedOnboarding: boolean;
  currentStep: number;
  setCurrentStep: (step: any) => void;
  testCallBtnRef: React.RefObject<HTMLDivElement>;
}

export const Onboarding: React.FC<OnboardingProps> = ({
  setOpened,
  openedOnboarding,
  setOpenedOnboarding,
  tourOpened,
  setReceiveTestCallTourOpen,
  testCallBtnRef,
  currentStep,
  setCurrentStep,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [stepsDone, setStepsDone] = useState<number[]>(loggedInUser.instruction_steps || []);
  const [isLoading, setIsLoading] = useState(false);
  const [didTalkWithAI, setDidTalkWithAI] = useState(true);
  const [modalVisible, setIsModalVisible] = useState(false);
  const [totalCalls, setTotalCalls] = useState(0);
  const username = loggedInUser.number as string;
  const formRef = useRef<FormInstance>(null);
  const [pendingTestingFlag, setPendingTestingFlag] = useState(false);
  const freeTestCallsLimitExceeded = totalCalls >= TOTAL_CALLS_LIMIT;
  const [onboardingCompleteReminderModal, setOnboardingCompleteReminderModal] = useState(false);

  useEffect(() => {
    if (loggedInUser?.instruction_steps?.length) {
      setStepsDone(loggedInUser.instruction_steps);
      if (loggedInUser.instruction_steps.length < 5) {
        setCurrentStep(loggedInUser.instruction_steps.length);
      }
      if (loggedInUser.instruction_steps.length < 7 && loggedInUser.instruction_steps.length >= 5) {
        setCurrentStep(loggedInUser.instruction_steps.length + 1);
      }
    }
  }, [loggedInUser, loggedInUser.instruction_steps]);

  const { mutate: changeTestingNumberMutation, status: changingTestingNumberStatus } = useMutation({
    mutationKey: ['changeTestingNumber'],
    mutationFn: updateTestingNumber,
    onSuccess(_, variables) {
      setIsModalVisible(false);
      dispatch(setTestingNumber({ testing_number: variables.testing_number }));
      notification.success({ message: 'Testing number changed successfully' });
    },
  });

  const handleFormSubmit = (values: { testing_number: string }) => {
    changeTestingNumberMutation(values);
  };

  const handleConfirmAction = () => {
    if (formRef.current) {
      formRef.current.submit();
      handleCallAgain();
    }
  };

  const UpdateStepsDoneMutation = useMutation({
    mutationKey: ['updateStepsDone'],
    mutationFn: updateStepsCompleted,
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      setStepsDone(data.updated_instruction_steps);
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }));
    },
  });
  const UpdatePendingTestingStatusMutation = useMutation({
    mutationKey: ['updatePendingTestingStatus'],
    mutationFn: updatePendingTestingStatus,
  });

  const IncrementTotalTestCallsMutation = useMutation({
    mutationKey: ['updatePendingTestingStatus'],
    mutationFn: incrementTotalTestCalls,
    onSuccess({ totalTestCalls }) {
      setTotalCalls(totalTestCalls);
    },
  });

  const makeATestCallMutation = useMutation({
    mutationKey: ['testCall'],
    mutationFn: makeTestCall,
    onSuccess() {
      setTotalCalls(totalCalls + 1);
      IncrementTotalTestCallsMutation.mutate();
      if (!loggedInUser.instruction_steps?.includes(1)) {
        updateStepsDone(1);
      }
      setTimeout(() => {
        setCurrentStep(2);
        setIsLoading(false);
      }, 5000);
    },
    onError() {
      setTotalCalls(totalCalls + 1);
      IncrementTotalTestCallsMutation.mutate();
      if (!loggedInUser.instruction_steps?.includes(1)) {
        updateStepsDone(1);
      }
      setTimeout(() => {
        setCurrentStep(2);
        setIsLoading(false);
      }, 5000);
    },
  });

  const makeVoidTestCall = useMutation({
    mutationKey: ['testCall'],
    mutationFn: makeTestCall,
    onSuccess() {
      setTotalCalls(totalCalls + 1);
      IncrementTotalTestCallsMutation.mutate();
      setIsLoading(false);
    },
    onError() {
      setTotalCalls(totalCalls + 1);
      IncrementTotalTestCallsMutation.mutate();
      setIsLoading(false);
    },
  });

  const handleTestCall = () => {
    posthog.capture('onb_test_call_clicked');
    setIsLoading(true);
    makeATestCallMutation.mutate();
  };

  const handleNext = (forward = true) => {
    if (
      loggedInUser.instruction_steps &&
      loggedInUser.instruction_steps?.length < 5 &&
      !stepsDone.includes(currentStep)
    ) {
      updateStepsDone(currentStep);
    }
    if (
      loggedInUser.instruction_steps &&
      loggedInUser.instruction_steps?.length >= 5 &&
      !stepsDone.includes(currentStep - 1)
    ) {
      updateStepsDone(currentStep - 1);
    }
    if (currentStep !== steps.length - 1) {
      if (forward) {
        setCurrentStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    }
  };

  const handleSubscription = async () => {
    UpdatePendingTestingStatusMutation.mutate({ pendingTesting: false });
    posthog.capture('onb_start_free_trial_clicked');
    dispatch(openModal({ modalId: MODAL_IDS.PLANS_MODAL }));
  };

  const handleNotTalkedWithAI = () => {
    posthog.capture('onb_not_talk_with_ai_clicked');
    setDidTalkWithAI(false);
  };

  const handleCallAgain = () => {
    posthog.capture('onb_call_again_clicked');
    setCurrentStep(1);
    setDidTalkWithAI(true);
  };

  const handleSetPendingTesting = (status: boolean) => {
    setPendingTestingFlag(status);
    UpdatePendingTestingStatusMutation.mutate({ pendingTesting: status });
    setOpenedOnboarding(!status);
  };

  const updateStepsDone = (stepNumber: number) => {
    if (loggedInUser.pendingTesting) {
      handleSetPendingTesting(false);
    }
    if (loggedInUser.instruction_steps?.includes(stepNumber)) return;
    UpdateStepsDoneMutation.mutate({ stepNumber });
  };

  const handleTourGuide = () => {
    posthog.capture('onb_taking_a_tour');
    setCurrentStep(4);
    setOpened(true);
    setOpenedOnboarding(false);
  };

  const handleChooseMyNumber = () => {
    posthog.capture('onb_choose_number_clicked');
    setPendingTestingFlag(true);
    setOpenedOnboarding(false);
    dispatch(setCurrentPage(ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS));
    UpdatePendingTestingStatusMutation.mutate({ pendingTesting: true });
  };

  const handleKeepExploring = () => {
    posthog.capture('onb_keep_exploring_clicked');
    handleSetPendingTesting(true);
    if (!freeTestCallsLimitExceeded) {
      setReceiveTestCallTourOpen(true);
    }
  };

  const handleContactSupport = () => {
    posthog.capture('onb_contact_support_clicked');
    handleSetPendingTesting(true);
    setDidTalkWithAI(true);
    dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CONTACT_SUPPORT));
  };

  const handleBookDemoCall = () => {
    window.open('https://calendly.com/d/cmq2-bzn-bth/ai-front-desk-onboarding-setup-help', '_blank');
  };
  const handleContinueOnboarding = () => {
    setCurrentStep(5);
  };

  const steps = [
    {
      title: OnboardingSteps.STEP_0,
      description:
        'Answer the phone 24/7 with our AI receptionist. AI Front Desk will bring you more customers, guaranteed.',
      image: step_0_image,
      content: (
        <CustomOnboardingButton
          onClick={() => {
            posthog.capture('onb_test_it_out_clicked');
            handleNext();
          }}
        >
          Test it Out
        </CustomOnboardingButton>
      ),
    },
    {
      title: isLoading ? OnboardingSteps.STEP_AI_CALLING : OnboardingSteps.STEP_1,
      description: isLoading
        ? `Your AI is currently calling you.`
        : `Grab your phone and click the button below to receive a test call. Our AI will call you at ${loggedInUser.testing_number || '--'}.`,
      image: step_1_image,
      content: (
        <>
          {!isLoading && (
            <CustomOnboardingButton icon={<PhoneOutlined />} onClick={handleTestCall} loading={isLoading}>
              {isLoading ? 'Initiating Call...' : 'Receive a Test Call'}
            </CustomOnboardingButton>
          )}
        </>
      ),
    },
    {
      title: !didTalkWithAI ? OnboardingSteps.STEP_ADDITIONAL_TESTING_OPTIONS : OnboardingSteps.STEP_2,
      description: '',
      image: '',
      content: (
        <>
          {!didTalkWithAI ? (
            <div className='flex items-center justify-center flex-col space-y-3'>
              <CustomOnboardingButton
                onClick={() => {
                  posthog.capture('onb_dont_have_phone_near_clicked');
                  setIsModalVisible(true);
                }}
                className='bg-red-400'
              >
                I don’t have {loggedInUser.testing_number || '--'} with me.
              </CustomOnboardingButton>
              <CustomOnboardingButton onClick={handleCallAgain}>Call me again. I wasn’t ready!</CustomOnboardingButton>
              <CustomOnboardingButton
                onClick={handleContactSupport}
                className='!text-sm !px-8 !bg-transparent !text-black hover:!text-button-success !shadow-lg !border-2'
              >
                Contact Support
              </CustomOnboardingButton>
              {!loggedInUser.testing_number?.startsWith('+1') && (
                <Alert
                  className='w-full'
                  type='error'
                  closable={false}
                  rootClassName='!bg-red-200 !border-2 !border-red-600'
                  message={
                    <p className='font-bold text-black text-sm text-center !mb-0'>
                      Having trouble receiving a call? Try calling your AI at{' '}
                      <a className='text-button-success' href={`tel:${RECEPTIONIST_NUMBER}`}>
                        {RECEPTIONIST_NUMBER}
                      </a>{' '}
                      using <span className='text-button-success'>{loggedInUser.testing_number}</span>.{' '}
                    </p>
                  }
                />
              )}
              <Paragraph
                className='font-semibold text-button-success underline text-lg cursor-pointer'
                onClick={() => setDidTalkWithAI(true)}
              >
                ⬅️ Go back
              </Paragraph>
            </div>
          ) : (
            <div className='flex flex-col items-center justify-center space-y-4'>
              <div className='flex items-center justify-center sm:space-x-3 sm:flex-row flex-col space-y-3 sm:space-y-0'>
                <CustomOnboardingButton onClick={handleNotTalkedWithAI} className='bg-red-400'>
                  No 😔
                </CustomOnboardingButton>
                <CustomOnboardingButton
                  onClick={() => {
                    posthog.capture('onb_yes_talk_with_ai_clicked');
                    handleNext();
                  }}
                >
                  Yes 🙌
                </CustomOnboardingButton>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: OnboardingSteps.STEP_3,
      description:
        'Let’s take a 30 second tour through the dashboard. AI Front Desk is designed for simplicity, so this won’t take long.',
      image: step_4_image,
      content: (
        <>
          <CustomOnboardingButton onClick={handleTourGuide}>Explore Dashboard 🚀</CustomOnboardingButton>
        </>
      ),
    },
    {
      title: OnboardingSteps.STEP_4,
      description:
        'Get started with AI Front Desk by booking your free onboarding demo call. Learn how to make the most of our features and have your questions answered by our experts!',
      image: step_optional_onb_image,
      content: (
        <div className='flex flex-col items-center justify-center space-y-4'>
          <CustomOnboardingButton onClick={handleBookDemoCall}>Book Demo Call 🗺️</CustomOnboardingButton>
          <CustomOnboardingButton onClick={handleContinueOnboarding} className='bg-slate-500 !text-base'>
            Continue Onboarding 👀
          </CustomOnboardingButton>
        </div>
      ),
    },
    {
      title: OnboardingSteps.STEP_5,
      description: `Join thousands of businesses who use AI Front Desk to get ~40%+ more customers and save millions of hours. Your new hire is 100x cheaper than a human receptionist, and doesn’t take days off.`,
      image: step_5_image,
      content: (
        <div className='flex flex-col items-center justify-center space-y-4'>
          <div className='flex items-center justify-center sm:space-x-3 sm:flex-row flex-col sm:space-y-0 space-y-4'>
            <CustomOnboardingButton
              block
              onClick={() => {
                posthog.capture('onb_view_pricing_plans_clicked');
                dispatch(openModal({ modalId: MODAL_IDS.PRICING_PLAN_MODAL }));
              }}
              className='!bg-black !text-sm !px-8 hover:!bg-transparent'
            >
              View Pricing Plans 💸
            </CustomOnboardingButton>
            <CustomOnboardingButton onClick={handleSubscription} className='!text-sm !px-8'>
              Start 7 Day Free Trial 🎉
            </CustomOnboardingButton>
          </div>
          <CustomOnboardingButton
            onClick={handleKeepExploring}
            className='!text-sm !px-8 !bg-transparent !text-black hover:!text-button-success !shadow-lg !border-2'
          >
            Keep Exploring 👀
          </CustomOnboardingButton>
        </div>
      ),
    },
    {
      title: OnboardingSteps.STEP_6,
      description: `You’ve just made an amazing investment for your business. Let AI answer your calls, while you focus on running your business. We’ll provide you free support forever. Let’s get set up!`,
      image: step_6_image,
      content: (
        <>
          <CustomOnboardingButton
            onClick={() => {
              posthog.capture('onb_set_up_clicked');
              handleNext();
            }}
            className='!text-sm !px-8'
          >
            Set Up ⚙️
          </CustomOnboardingButton>
        </>
      ),
    },
    {
      title: OnboardingSteps.STEP_7,
      description: `Let’s get you a shiny new AI number. You can forward calls to this number or use it as your business phone directly. It will always be linked to this account.`,
      image: step_7_image,
      content: (
        <>
          <CustomOnboardingButton onClick={handleChooseMyNumber}>Choose My Number 📞</CustomOnboardingButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!loggedInUser.number) {
      return;
    }
    setPendingTestingFlag(loggedInUser.pendingTesting || false);
    setTotalCalls(loggedInUser.totalTestCalls || 0);
    if (
      !tourOpened &&
      !openedOnboarding &&
      !loggedInUser.pendingTesting &&
      !(loggedInUser.instruction_steps?.length === steps.length - 1)
    ) {
      setOpenedOnboarding(true);
    }
  }, [loggedInUser]);

  const baseClass = 'onboarding-container';

  useEffect(() => {
    let interval: any;
    if (
      loggedInUser.demo &&
      !(loggedInUser.instruction_steps?.length === steps.length) &&
      pendingTestingFlag &&
      !openedOnboarding &&
      !tourOpened
    ) {
      interval = setInterval(() => {
        setOnboardingCompleteReminderModal(true);
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [loggedInUser, openedOnboarding, tourOpened, steps.length, pendingTestingFlag]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refresh = params.get('refresh');

    if (refresh === 'true') {
      params.delete('refresh');

      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { replace: true }
      );

      window.location.reload();
    }
  }, [location, navigate]);

  return (
    <div>
      <div className='fixed bottom-4 left-4' ref={testCallBtnRef}>
        {pendingTestingFlag && !openedOnboarding && loggedInUser.demo && (
          <CustomOnboardingButton
            onClick={() => {
              if (freeTestCallsLimitExceeded) {
                setOpenedOnboarding(true);
                return;
              }
              setIsLoading(true);
              makeVoidTestCall.mutate();
            }}
            className='!text-sm !px-8 !shadow-lg !border-2'
            loading={isLoading}
          >
            {isLoading
              ? 'Calling...'
              : !freeTestCallsLimitExceeded
                ? `Receive a Test Call 📞 ${totalCalls}/${TOTAL_CALLS_LIMIT}`
                : 'Start Free Trial'}
          </CustomOnboardingButton>
        )}
      </div>

      <Modal
        open={openedOnboarding}
        centered
        footer={null}
        closable={[5, 6].includes(currentStep)}
        styles={{
          content: {
            borderRadius: '50px',
          },
        }}
        className={`bg-opacity-50 backdrop-blur-lg ${baseClass}`}
        onClose={() => setOpenedOnboarding(false)}
        onCancel={() => setOpenedOnboarding(false)}
      >
        <div className='flex flex-col items-center p-4 bg-white rounded-lg'>
          {/* Image */}
          {steps[currentStep].image ? (
            <Image
              src={steps[currentStep].image}
              alt={`Step ${currentStep}`}
              preview={false}
              className='w-full !h-[300px] object-cover rounded-[100px]'
            />
          ) : null}
          {/* Title */}
          <Title level={3} className='mt-4 text-center'>
            {steps[currentStep].title}
          </Title>

          {/* Description */}
          {steps[currentStep].description && (
            <Paragraph className='text-center px-6'>{steps[currentStep].description}</Paragraph>
          )}

          {/* Content */}
          <div className='mt-4'>{steps[currentStep].content}</div>

          {/* Pagination Dots */}
          <div className='flex justify-center mt-6'>
            {steps.map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full mx-1 ${currentStep === index ? 'bg-black' : 'bg-gray-300'}`}
              />
            ))}
          </div>
        </div>

        {/* Plans Modal */}
        <PlansModal username={username} />

        {/* Change testing number modal  */}
        <CustomModal
          title='Change Testing Number'
          isModalOpen={modalVisible}
          confirmAction={handleConfirmAction}
          cancelAction={() => setIsModalVisible(false)}
          footer={[
            <Button key='back' onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
            <Button
              key='submit'
              type='primary'
              onClick={handleConfirmAction}
              loading={changingTestingNumberStatus === 'pending'}
            >
              Change
            </Button>,
          ]}
        >
          <ChangeTestingNumberForm ref={formRef} onSubmit={handleFormSubmit} />
        </CustomModal>
      </Modal>

      <Modal
        open={onboardingCompleteReminderModal}
        centered
        footer={null}
        closable={true}
        onCancel={() => setOnboardingCompleteReminderModal(false)}
        className={`bg-opacity-50 backdrop-blur-lg ${baseClass}`}
      >
        {/* Modal Content */}
        <div className='text-center'>
          <div className='mb-4 text-3xl'>
            🚀 <span className='font-bold'>You're Almost There!</span> 🚀
          </div>

          {/* Description */}
          <div className='flex flex-col space-y-4 my-4'>
            <div className='text-lg text-gray-700'>
              You've made great progress, but there are just a few more steps left to complete your onboarding. 🏆
            </div>
            <span className='text-blue-600 font-semibold text-base'>
              Let's finish up and unlock all the benefits waiting for you!
            </span>
          </div>
          {/* Call-to-action Button */}
          <CustomOnboardingButton
            onClick={() => {
              setOnboardingCompleteReminderModal(false);
              setOpenedOnboarding(true);
            }}
          >
            Complete Onboarding 💪
          </CustomOnboardingButton>
        </div>
      </Modal>
      <PricingPlansModal />
    </div>
  );
};
